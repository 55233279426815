import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '../../providers/StoreProvider';
import { withSnackBar } from '../../providers/snackBar';

import actions from '../../actions';
import Loader from '../../components/Loader';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import CatalogSelectCategorie from '../../components/CatalogSelectCategorie';

function CataloguePage(props) {
    const intl = props.intl;
    const selectedVehicule = props.globalState.selectedVehicule;

    const [displayLoader, setDisplayLoader] = useState(false);

    const [displayArticlesInCat, SetDisplayArticlesInCat] = useState(undefined);
    const [directArticles, setDirectArticles] = useState([]);

    useEffect(() => {
        if (displayArticlesInCat) {
            setDisplayLoader(true);
            async function fetchData() {
                try {
                    if (props.globalState.brands) {

                        let _articles = [];
                        for (let brand of props.globalState?.brands) {
                            for (let article of brand.articles) {
                                if (article.assemblyGroupNodeId == displayArticlesInCat.assemblyGroupNodeId) {
                                    _articles.push({ ...article, brandId: brand?.brandId, brandLogoID: brand?.brandLogoID, brandName: brand?.brandName });
                                }
                            }
                        }

                        let _directArticles = [];
                        for (let article of _articles) {
                            let articleDetails = (await props.dispatch(actions.catalogue.getArticleDetails(article.articleId))).articleDetails || {};
                            let articleAttributes = (await props.dispatch(actions.catalogue.getArticleAttributes(article.articleId, selectedVehicule?.tecdocId))).articleAttributes || [];
                            let articleDocuments = (await props.dispatch(actions.catalogue.getArticlesDocuments(article.articleId))).articleDocuments || [];
    
                            let articleThumbnails = articleDocuments.filter((e) => e.docTypeId == 5);


                            _directArticles.push({ ...article, ...articleDetails, articleAttributes: [...articleAttributes], articleDocuments: [...articleDocuments], articleThumbnails: [...articleThumbnails]});
                        }

                        if (selectedVehicule) {
                            let useAttributeA = false;
                            let attributeAFound = false;
                           _directArticles = _directArticles.map((e) => {
                                for (let attribute of e.articleAttributes) {
                                    if (attribute.attrName == 'Code moteur') {
                                        useAttributeA = true;
                                        if (attribute.attrValue == selectedVehicule?.engineCode) {
                                            attributeAFound = true;
                                        }
                                    }
                                }

                               if (useAttributeA == true && attributeAFound == true){
                                   return e;
                               } else if (useAttributeA == false ){
                                return e;
                               }
                                
                            });

                            _directArticles = _directArticles.filter(e=> e!= undefined);
                        }
                        setDirectArticles(_directArticles);
                    }
                } catch (err) {
                    props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
                } finally {
                    setDisplayLoader(false);
                }
            }

            fetchData();
        }
    }, [displayArticlesInCat]);

    function generatePreviewThumbnails(thumbnails) {
        let _items = [];

        if (thumbnails?.length > 0) {
            _items = thumbnails?.map((th) => {
                return <img src={"https://webservice.tecalliance.services/pegasus-3-0/documents/" + 20645 + "/" + th.docId + "/1"} alt="..." width="200px" />;
            })
        } else {
            _items.push(<img width="200px" src={"/no-image-available.jpg"} alt="..." />)
        }

        return <AliceCarousel disableButtonsControls disableDotsControls items={_items} />
    }


    return <Box>

        <Loader display={displayLoader} />

        <Grid container spacing={2}>
            <Grid item xs={3} sx={{ textAlign: 'left' }} >
                <CatalogSelectCategorie
                    onSelect={(cat) => {
                        if (cat.hasChilds == false) {
                            SetDisplayArticlesInCat(cat);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={9} sx={{ textAlign: 'left' }} >
                {displayArticlesInCat && directArticles?.map((article) => {
                    if (article.assemblyGroupNodeId == displayArticlesInCat.assemblyGroupNodeId) {
                        return <Box style={{ border: "solid 1px #e8e8e8", borderRadius: "16px", marginBottom: "15px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={{ textAlign: 'left' }} >
                                    {generatePreviewThumbnails(article?.articleThumbnails)}
                                </Grid>
                                <Grid item xs={8} sx={{ textAlign: 'left' }} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ textAlign: 'left' }} style={{ paddingTop: "25px" }}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                <b>{article.brandName}</b> - {article.articleNo} - {article?.articleName || ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} style={{ paddingBottom: "15px" }}>
                                        <Grid item xs={12} sx={{ textAlign: 'left' }} style={{ paddingTop: "25px" }}>
                                            {article?.articleAttributes?.sort().map((attr) => {
                                                return <Typography variant="body2" gutterBottom component="div">
                                                    {attr.attrName} : <b>{attr.attrValue + (attr?.attrUnit ? (" " + attr?.attrUnit) : "")}</b>
                                                </Typography>
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: 'right' }} >

                                </Grid>
                            </Grid>
                        </Box>
                    }
                })}
            </Grid>
        </Grid>
    </Box>
}

export default withStoreProvider(withSnackBar(injectIntl(CataloguePage)));